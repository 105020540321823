<script>

// eslint-disable-next-line no-unused-vars
import Layout from "../../layouts/main";
// eslint-disable-next-line no-unused-vars
import PageHeader from "@/components/page-header";
const Area = require('./../../../api/area');
const SoalType = require('./../../../api/soalType');
const Soal = require('./../../../api/soal');
const Matpel = require('./../../../api/matpel');
const Jenjang = require('./../../../api/jenjang');
import Swal from "sweetalert2";
import tinymce from 'vue-tinymce-editor';
import CKEditor from 'ckeditor4-vue';

export default {
  // eslint-disable-next-line vue/no-unused-components
    components: { Layout, PageHeader, tinymce, CKEditor },
    page: {
        title: "Soal",
        meta: [{ name: "description", content: "List Of Schools Area" }]
    },
    data() {
        return {
            title: "Soal",
            items: [
                {
                text: "Soal",
                active: true
                }
            ],
            lists : [],
            rowEdit: {},
            states : 0,
            types : [],
            type : null,
            matpels : [],
            matpel : 0,
            loading : false,
            state : 'list',
            isError :false,
            message : '',
            Area : new Area(),
            soalType : new SoalType(),
            Matpel : new Matpel(),
            Soal : new Soal(),
            Jenjang : new Jenjang(),
            jenjangs : [],
            id_jenjang : 0,
            filters : {},
            total : 0,
            offset : 0,
            limit  : 10,
            paginations : [],
            pagination : null,
            Selected: [],
        }
    },
    mounted(){
        this.getType();
        this.getMatpel();
        // this.getData();
        this.getJenjang();
    },
    methods: {
        getType(){
            this.soalType.data(this.matpel)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.types = res.data_soal_type.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getJenjang(){
            this.Jenjang.data()
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.jenjangs = res.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getMatpel(){
            this.Matpel.data(this.id_jenjang)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.matpels = res.data.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        reset(){
            this.matpel = null;
            this.type = null;
            this.filters = {};
            this.pagination = null;
            this.states = 0;
            this.getData();
        },
        changePaket(){
            console.log(this.Selected);
        },
        showCreate(){
            this.$router.push({ name: 'addSoal' })
        },
        soalPaket(){
            this.$router.push({ name: 'paketSoal' })
        },
        selectMatpel(item){
            this.matpel = item;
            this.getData();
        },
        selectType(item){
            this.type = item;
            this.getData();
        },
        getData(){
            this.filters = {};
            console.log(this.filters);
            if(this.type != null){
                this.filters.id_type = this.type
            }
            this.Soal.data(this.filters)
            .then((res)=>{
                if(res.success){
                    this.lists = res.data_soal.data.map((i)=>{
                        // eslint-disable-next-line no-unused-vars
                        // var pembahasan = '';
                        // if(i.pembahasan != null){
                        //         //   src={`data:${props.images[0].mimetype};base64,${Buffer.from(props.images[0].file.data).toString('base64')}`}
                        //     pembahasan = `data:image/png;base64,${i.pembahasan}`
                        // }
                        return {
                            choicesoals : i.choicesoals,
                            createdAt : i.createdAt,
                            id : i.id,
                            id_matpel : i.id_matpel,
                            id_type : i.id_type,
                            isEssay : i.isEssay,
                            jawaban : i.jawaban,
                            pembahasan : 'http://103.41.207.247:3000/'+i.pembahasan,
                            soals : i.soals,
                            imgPembahasan : i.imgPembahasan,
                            updatedAt : i.updatedAt
                        }
                    })
                }else{
                    console.log("kosong")
                }
            })
        },
        changePagination(pagination) {
            this.offset = pagination.offset
            this.limit = pagination.limit
            this.pagination = pagination
            this.getData();
        },
        hapus(row){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger ml-2"
                },
                buttonsStyling: false
            });
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    this.Soal.hapus({id:row.id})
                    .then((res)=>{
                        this.reset()
                        if(res.success){
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }else{
                            swalWithBootstrapButtons.fire(
                            "Cancelled",
                            "Cancelled",
                            "error"
                            );
                        }
                    })
                    .catch((err)=>{
                            swalWithBootstrapButtons.fire(
                            "Cancelled",
                            err,
                            "error"
                            );
                    })
                }
            });
        },
        edit(row){
          console.log(row);
          this.states = 1;
          this.rowEdit = row
          // this.$store.commit('soal/SET_ID', row);
          // this.$router.push({ name: 'editSoal' })
        },
        Simpan(){
          console.log(this.rowEdit);
          this.Soal.validateEdit([this.rowEdit])
          .then((res)=>{
              if(res.haveError){
                  console.log('error');
              }else{
                console.log([this.rowEdit]);
                this.$store.dispatch('soal/saveEdit',{soal:[this.rowEdit]})
                this.reset()
              }
          })
          .catch(e=>{
              this.loading = false;
              console.log(e);
              console.log('error');
          })
        },
        uploadImage(event){
            this.rowEdit.fotoPembahasan = event.target.files[0];
        },
        uploadImageSoal(event){
            var formData = new FormData();
            formData.append('picture',event.target.files[0]);
            this.loading  = true;
            this.Soal.UploadFile(formData)
            .then(res=>{
                if(res.success){
                    this.rowEdit.soals += `&nbsp;<img src="http://103.41.207.247:3000/${res.data}" alt="${res.data}" width="120" height="100" />`
                }else{
                this.$toasted.error("Upload Images Failed, Please Try Again Later", {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration : null
                });
                }
            this.loading  = false;
            this.$bvModal.hide('modal-image')
            })
            .catch(e=>{
            this.$bvModal.hide('modal-image')
                this.loading = false;
                console.log(e)
            })
        }
    },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="states == 0">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body">
                    <button class="btn btn-success m-2" @click="showCreate()"><i class="bx bx-plus-circle"></i> Add New</button>
                    <button class="btn btn-warning m-2" @click="reset()"><i class="bx bx-reset"></i> Reset</button>
                    <button class="btn btn-warning m-2" @click="soalPaket()"><i class="bx bx-reset"></i> Paket soal-soal</button>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Filter : </h4>
                    <div class="form-group">
                        <label class="control-label">Jenjang <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="id_jenjang" @change="getMatpel()">
                            <option :value="null" hidden>Select Jenjang</option>
                            <option v-for="item in jenjangs" :key="item.id" :value="item.id">{{item.jenjang}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Mata Pelajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="matpel" @change="getType()">
                            <option :value="null" hidden>Select Mata Pelajaran</option>
                            <option v-for="item in matpels" :key="item.id" :value="item.id">{{item.nama}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Paket Soal <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="type" @change="getData()">
                            <option :value="null" hidden>Select Paket Soal</option>
                            <option v-for="item in types" :key="item.id" :value="item.id">{{item.type}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="card">
                <div class="card-body">
                    <h2 class="">Soal </h2>
                    <h6>Total : {{lists.length}}</h6>
                    <hr>
                    <div v-if="lists.length == 0" class="d-flex justify-content-center align-item-center p-4 m-4">
                        <img src="@/assets/images/empty.png" class="img-fluid p-4 m-4"/>
                    </div>
                    <div v-else >
                      <div class="m-2" v-for="(item,index) in lists" :key="item.id">
                        <!-- <div class="custom-control custom-checkbox">
                          <input type="checkbox" v-model="Selected" v-bind:value="item.id" v-bind:id="item.id"> -->
                          <!-- <label for="checkbox">{{ Selected }}</label> -->
                        <!-- </div> -->
                        <div class="d-flex flex-row justify-content-between">
                          <p v-html="item.soals"><b>{{index+1}}. </b> <b-badge v-if="item.isEssay" variant="primary">Essay</b-badge></p>
                          <div class="d-flex flex-row">
                            <button class="btn btn-sm  btn-warning" @click="edit(item)" v-b-tooltip.hover title="edit"><i class="bx bx-edit"></i></button>
                          </div>
                          <div class="d-flex flex-row">
                            <button class="btn btn-sm  btn-danger" @click="hapus(item)" v-b-tooltip.hover title="Hapus"><i class="bx bx-trash-alt"></i></button>
                          </div>
                        </div>
                        <div v-if="!item.isEssay" class=" ml-4 pl-2">
                          <p v-for="(choice,index) in item.choicesoals" :key="choice.id"><b>{{String.fromCharCode(65+index)}}.</b> {{choice.choice}}</p>
                        </div>
                        <p><b>JAWABAN : </b></p>
                        <div class="bg-light p-2">
                          <p>{{item.jawaban}}</p>
                        </div>
                        <p><b>PEMBAHASAN : </b></p>
                        <div class="img img-responsive">
                          <div v-html="item.pembahasan"  v-if="item.imgPembahasan"><b>{{index+1}}. </b></div>
                          <img :src="item.pembahasan" v-else alt="" height="120px" width="120px">
                        </div>
                        <hr>
                        <br>
                      </div>
                      <!-- <div class="form-group">
                          <label class="control-label">Paket Soal <span class="text-danger">*</span></label>
                          <select class="form-control" v-model="type" @change="changePaket()">
                              <option :value="null" hidden>Select Paket Soal</option>
                              <option v-for="items in types" :key="items.id" :value="items.id">{{items.type}}</option>
                          </select>
                      </div> -->
                    </div>
                    <div class="overflow-auto" v-if="paginations.length > 0">
                       <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                </li>
                                <li  v-for="item in paginations" :key="item.number" @click="changePagination(item)" :class="pagination != null ? pagination.number == item.number ? 'active page-item':'page-item':'page-item'"><a class="page-link" href="#">{{item.number}}</a></li>
                                <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="test" v-show="states == 1">
      <button class="float btn btn-warning btn-gallery" v-b-modal.modal-image v-b-tooltip.hover title="Add Image to Soal"><i class="bx bx-image-alt font-size-18"></i></button>
      <div class="card" >
          <div class="card-body">
              <div class="d-flex flex-row justify-content-between">
                  <!-- <h4 class="card-title">Soal Details Nomor ke {{index+1}}</h4> -->
                  <!-- <button class="btn btn-xs btn-danger" @click="hapusSoalList(index)"><i class="fas fa-times"></i> delete</button> -->
              </div>
              <div class="form-group">
                  <label class="control-label">Question : <span  class="text-danger">*</span></label>
                  <!-- <ckeditor v-model="item.soals" tag-name="textarea" :config="{bodyId:'hello'+index}" :name="index" :id="index"></ckeditor> -->
                  <tinymce id="editor-edit" ref="tm" class="editor" height="500px"  v-model="rowEdit.soals" ></tinymce>
              </div>
              <div class="form-check mb-3">
                  <input class="form-check-input" v-model="rowEdit.isEssay" type="checkbox" value id="defaultCheck1" />
                  <label class="form-check-label" for="defaultCheck1">Centang Jika Ini Soal Essay</label>
              </div>
              <div class="form-check mb-3">
                  <input class="form-check-input" v-model="rowEdit.isFile" type="checkbox" value id="defaultCheck2" />
                  <label class="form-check-label" for="defaultCheck2">Centang Jika Ini Soal File</label>
              </div>
              <div v-if="!rowEdit.isFile">
                <div class="form-group" v-if="rowEdit.isEssay == 1">
                  <label class="control-label">Answer : <span class="text-danger">*</span></label>
                  <textarea rows="6" class="form-control" v-model="rowEdit.jawaban"></textarea>
                </div>
                <div class="form-group" v-if="rowEdit.isEssay == 0">
                  <b-input-group class="my-2" v-for="(choice,k) in rowEdit.choicesoals" :key="k">
                    <b-alert variant="danger" :show="choice.isError">{{choice.errorMessage}}</b-alert>
                    <template #prepend>
                      <div v-if="k==0">
                        <b-input-group-prepend is-text>A</b-input-group-prepend>
                      </div>
                      <div v-if="k==1">
                        <b-input-group-prepend is-text>B</b-input-group-prepend>
                      </div>
                      <div v-if="k==2">
                        <b-input-group-prepend is-text>C</b-input-group-prepend>
                      </div>
                      <div v-if="k==3">
                        <b-input-group-prepend is-text>D</b-input-group-prepend>
                      </div>
                    </template>
                    <b-form-input v-model="choice.choice"></b-form-input>
                    <template #append>
                      <b-input-group-prepend is-text>
                        <b-form-radio :name="`radio-${k}`" v-model="rowEdit.jawaban" :value="k" :checked="choice.isTrue"></b-form-radio>
                      </b-input-group-prepend>
                    </template>
                  </b-input-group>
                </div>
                <div class="form-check mb-3">
                  <input class="form-check-input" v-model="rowEdit.imgPembahasan" type="checkbox" value id="isPembahasan" />
                  <label class="form-check-label" for="isPembahasan">Centang Jika Pembahasan tidak berbentuk Image</label>
                </div>
                <div class="form-group" v-show="rowEdit.imgPembahasan">
                  <label class="control-label">Pembahasan : <span  class="text-danger">*</span></label>
                  <tinymce id="editorpem" ref="tmp" class="editor" height="500px"  v-model="rowEdit.pembahasan" ></tinymce>
                </div>
                <div class="form-group" v-show="!rowEdit.imgPembahasan">
                  <label class="control-label">Pembahasan : <span class="text-danger">*</span></label><br>
                  <input type="file" accept="image/*" @change="uploadImage($event,'index')" :id="`file-input-${rowEdit.id}`">
                </div>
              </div>

          </div>
          <div class="form-group d-flex flex-row justify-content-between">
            <div>
              <button class="btn btn-danger mx-2 " @click="back()">Kembali</button>
              <button class="btn btn-primary mx-2" @click="Simpan()">Simpan</button>
            </div>
            <!-- <button class="btn btn-success flota-right" @click="TambahSoal()">Tambah Soal</button> -->
          </div>
          <b-modal id="modal-image" size="md" title="Upload Image For Insert to Soal">
            <input type="file" accept="image/*" @change="uploadImageSoal($event)" id="fileSoal">
            <template #modal-footer="{ ok, cancel }">
              <!-- Emulate built in modal footer ok and cancel button actions -->
              <b-button size="sm" variant="success" @click="ok()">
                <i class="bx bx-upload"></i> UPLOAD
              </b-button>
              <b-button size="sm" variant="danger" @click="cancel()">
                Cancel
              </b-button>
            </template>
          </b-modal>
      </div>
    </div>
  </Layout>
</template>
